import { Link, useSearchParams } from "@remix-run/react";
import { Job } from "~/types/Job";
import { JobCard } from "./JobCard";
import { SignupForm } from "~/components/SignupForm";

const SIZE = 10;

interface JobsTableProps {
  jobs: Job[];
  showAll?: boolean;
}

export function JobsTable({ jobs, showAll }: JobsTableProps) {
  const shownJobs = showAll ? jobs : jobs.slice(0, SIZE);
  return (
    <ul
      role="list"
      className="overflow-hidden bg-gray-700 relative shadow-sm ring-1 ring-gray-900/5 rounded-xl"
    >
      {shownJobs.map((job) => (
        <JobCard key={job.id} job={job} />
      ))}
      {!showAll && (
        <div className="absolute bottom-0 right-0 left-0 z-30">
          <div className="w-full h-16 z-50 bg-gradient bg-gradient-to-b from-transparent to-gray-900"></div>
        </div>
      )}
    </ul>
  );
}

const PageLink = ({
  page,
  active,
  params,
}: {
  page: number;
  active: boolean;
  params: string;
}) => {
  return (
    <Link
      to={`?page=${page}&${params}`}
      preventScrollReset={true}
      className={`px-4 py-2 font-medium ${
        active ? "text-lime-400" : "text-gray-400"
      }`}
    >
      {page}
    </Link>
  );
};

interface PaginatedJobsTableProps {
  jobs: Job[];
  size: number;
  count: number;
  authenticated?: boolean;
}

export function PaginatedJobsTable({
  jobs,
  size,
  count,
  authenticated,
}: PaginatedJobsTableProps) {
  const [searchParams] = useSearchParams();
  const remainingParams = new URLSearchParams(searchParams.toString());
  remainingParams.delete("page");

  const activePage = parseInt(searchParams.get("page") || "1", 10);
  const totalPages = Math.ceil(count / size);

  let visiblePages = [];
  if (totalPages <= 5) {
    visiblePages = Array.from({ length: totalPages }).map((_, i) => i + 1);
  } else {
    if (activePage <= 3) {
      visiblePages = [1, 2, 3, 4, 5];
    } else if (activePage >= totalPages - 2) {
      visiblePages = [
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      visiblePages = [
        activePage - 2,
        activePage - 1,
        activePage,
        activePage + 1,
        activePage + 2,
      ];
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <JobsTable showAll={authenticated} jobs={jobs} />
      {authenticated && (
        <div className="flex justify-center">
          {visiblePages[0] > 1 && (
            <>
              <PageLink
                key={1}
                page={1}
                active={1 === activePage}
                params={remainingParams.toString()}
              />
              {visiblePages[0] > 2 && (
                <span className="my-auto  text-gray-400">...</span>
              )}
            </>
          )}
          {visiblePages.map((pageNumber, index) => (
            <PageLink
              key={index}
              page={pageNumber}
              active={pageNumber === activePage}
              params={remainingParams.toString()}
            />
          ))}
          {visiblePages[visiblePages.length - 1] < totalPages && (
            <>
              {visiblePages[visiblePages.length - 1] < totalPages - 1 && (
                <span className="my-auto  text-gray-400">...</span>
              )}
              <PageLink
                key={totalPages}
                page={totalPages}
                active={totalPages === activePage}
                params={remainingParams.toString()}
              />
            </>
          )}
        </div>
      )}
      {!authenticated && (
        <div className="-mt-9">
          <SignupForm />
        </div>
      )}
    </div>
  );
}
